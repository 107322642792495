import './App.css';
import Routing from '../src/Config/Routing';
function App() {

  return (
    <Routing></Routing>
  );
}

export default App;
