
import Auth from "../../Components/Common/Auth";
function Login() {
    return (
      <div>
          <Auth></Auth>
      </div>

    );
  }
  
  export default Login;
  